export default {
    init() {
        // JavaScript to be fired on the home page

        $('.dienst__item__subtitle').matchHeight();

        // Slick slider diensten
        $('.js-slider-diensten').slick({
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            variableWidth: true,
            centerMode: true,
            swipeToSlide: true,

        });

        $('.slider__pagination__left').click(function(){
            $('.js-slider-diensten').slick('slickPrev');
        })

        $('.slider__pagination__right').click(function(){
            $('.js-slider-diensten').slick('slickNext');
        })
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
