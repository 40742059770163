export default {
    init() {
        // JavaScript to be fired on all pages

        $('.datepicker').each(function() {
            $(this).attr('autocomplete', 'off');
        });

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        $('.front__intro .btn--down').click(function() {
            $('body,html').animate(
                {
                    scrollTop: $('.front__timeline').offset().top - 100,
                },
                500
            );
        });

        $('.dienst-post__link').click(function() {
            var id = $(this).attr('id');
            $('.dienst-post__link').addClass('disable');
            $(this).removeClass('disable');
            $('.dienst__content').removeClass('active');
            $('.dienst__content').filter('#' + id).addClass('active');

            $('body,html').animate(
                {
                    scrollTop: $('.dienst').offset().top - 100,
                },
                500
            );
        });

        $('.dienst__content__close').click(function() {
            $('.dienst__content').removeClass('active');

        });

        $('.dienst__tekst').each(function() {
            var inner = $(this).find('.dienst__tekst__inner');
            if(inner.text().length > 800){
                var shortText = inner.text();
                shortText = shortText.substring(0, 800);
                inner.addClass('fullArticle').hide();
                shortText += '<a class="btn btn--secondary btn--next read-more-link">Lees verder</a>';
                inner.append('<a class="btn btn--secondary btn--next read-less-link">Lees minder</a>');
                $(this).append('<p class="preview">'+shortText+'</p>');
            }
        });

        $(document).on('click', '.read-more-link', function () {
            $(this).parent().hide().prev().show();
        });

        $(document).on('click', '.read-less-link', function () {
            $(this).parent().hide().next().show();
        });

        $('.sidebar__nav li .page-item-touch').click(function () {
            $(this).parent('li').toggleClass('active');
        });

        // $('.dienst__faq__button').click(function(){
        //     $('.dienst__faq__item').removeClass('active');
        //     $('.dienst__faq__item').find('.dienst__faq__content').slideUp('slow', function() {
        //         // Animation complete.
        //     });
        //     if($(this).parent().hasClass('active')) {
        //         $(this).parent().removeClass('active');
        //         $(this).parent().find('.dienst__faq__content').slideUp('slow', function() {
        //             // Animation complete.
        //         });
        //     } else {
        //         $(this).parent().addClass('active');
        //         $(this).parent().find('.dienst__faq__content').slideUp('slow', function() {
        //             // Animation complete.
        //         });
        //     }
        // });

        $('.sidebar__nav__list .page_item_has_children').append('<span class="page-item-touch"></span>');
        $('.page-item-touch').on('click', function () {
            $(this).parent().toggleClass('open');
            console.log('test');
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
